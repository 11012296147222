import React from "react";
import "./styles/index.css";
import "./styles/addToCompare.css";

function AddToCompare(props){
    return (
    <div className="compare-card-div"> 
        <div className="add-to-compare-container" onClick={props.showCardOptions}>
            <div> + </div>
            <div> Add To Compare </div>

        </div>
    
    
    </div>)
}


export default AddToCompare;