import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";

import Layout from "../components/layout";
import { fetchCardById } from '../apis';
import CompareCardData from '../components/compareCardData';
import AddToCompare  from '../components/compareCardData/addToCompare';
import SelectCardModal from '../components/selectCardModal';
import "./styles/card-compare.css";


function CompareCards({location}){

    const [cardsData , setCardsData] = useState([]);
    const [showCardSelectModal, setShowCardSelectModal] = useState(false);
    const compareCardsSubHeadingA = "Compare up to 3 credit cards from our partners using the side-by-side card comparison tool below.";
    const compareCardsSubHeadingB = "Search for a credit card you have in mind or browse our list of top picks.";

    function showCardOptions(ev){
        ev.preventDefault();
        setShowCardSelectModal(true);
    }

    function setSelectCard(cardId){
        const url_params = new URLSearchParams(window.location.search);
        const cardsArray = (url_params.get("cards") || "").split(":")
        if(!cardsArray.includes(cardId)){
            cardsArray.push(cardId);
        }
        url_params.delete("cards");
        setShowCardSelectModal(false);
    
       const cards = cardsArray.reduce(
        (acc, item , ind ) => { 
            if(ind === cardsArray.length-1){
                acc+= item;
            } else { 
                acc+= item + ":";
            }  ; return acc; } , '');   
        
        let newURl = "/compare-cards/?";
        for( let params of url_params.keys() ){
            newURl +=  params + "=" + url_params.get(params) + "&";
        }
        newURl += `cards=${cards}`;
        navigate(newURl);
    }   

    function cancelAdd(ev){
        ev.preventDefault();
        setShowCardSelectModal(false);
    }


    useEffect( () => {
        const url_params = new URLSearchParams(window.location.search);
        if(url_params.has("cards")){
            const cardsArray = url_params.get("cards").split(":").filter(ele => ele.length >0);
            const promiseArr = cardsArray.map( ele => fetchCardById(ele) );
            Promise.all(promiseArr).then( 
                response => {
                    const cardsData = response.map( card => card.data[0] );
                    const localStorageData = cardsData.map( ele => ({id : ele.key, imageUrl : ele.imageUrl}) );
                    localStorage.setItem("compareCardIds", JSON.stringify(localStorageData));
                    setCardsData(cardsData);
                }
             ).catch(err => {
                window.dispatchEvent(new CustomEvent("showSnackBar", {detail : { msg : JSON.stringify(err.message), type : "alert" }}))
                // console.log(err);
             })
        }
    }, [location.search] )

    return (
        <Layout>

            <div style={{textAlign : "center" }}>
                <h1> {"Compare Cards"} </h1>
                <div style={{ color:"#808080" , fontWeight:"400" , fontSize: "1.3rem"}}> 
                    <div>  {compareCardsSubHeadingA}  </div>
                    <div> {compareCardsSubHeadingB} </div>
                </div>
            </div>
            
            <div className="card-compare-container">
                {cardsData.map(ele => <CompareCardData data={ele} key={ele.key} />)}
                {cardsData.length <3 ? <AddToCompare showCardOptions={showCardOptions} /> : null }
            </div>

            { showCardSelectModal &&  <SelectCardModal setSelectCard={setSelectCard} cancelAdd={cancelAdd} />}

        </Layout>
    )
}

export default CompareCards;