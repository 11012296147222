import React, { useState } from "react";
import {fetchCardByName} from '../../apis';

import { debouncedInp } from '../../utilities/utilfns';
import "./index.css";

function SelectCardModal(props){

    const [cardsList, setCardsList] = useState([]);
    const [noCardFound, setNoCardFound] = useState(false);

    function fetchCards(ev){
        if(ev && ev.target && ev.target.value){
            const value = ev.target.value;
            fetchCardByName(value).then(resp => {
                const { data } = resp;
                setNoCardFound(data.length == 0);
                setCardsList(data);
            })
        }
    }

    function selectCard(ev){
        const cardId = ev.target.id;
        props.setSelectCard(cardId);
    }

    return (
        <div className="select-card-modal">
            <div className="select-card-modal-content"> 
                <div className="modal-content-header"> 
                <div className="remove-card" style={{cursor : "pointer"}} onClick={props.cancelAdd}> 
                    <div style={{ height: "1.4rem", width: "1.4rem", marginRight : "1rem"}}> 
                    <img  
                    alt="close"
                    style={{height:"100%", width:"100%", objectFit:"contain"}} 
                    src="https://credzyimages.s3.ap-south-1.amazonaws.com/icons/close.svg"/>  </div>
                </div>
                    <span> {"Add a Card to Comparison "}  </span>
                </div>
                <div className="modal-content-body">  
                    
                    <div className="modal-content-body-label"> {" Search or select a card and it'll be added to comparison."} </div>
                    <input className="modal-content-body-input" onChange={debouncedInp(fetchCards)} />  
                    <div>

                        {noCardFound  && cardsList.length === 0 && "Sorry No Cards Available with given name"}
                        {cardsList.length > 0 && (
                            <ul className="cards-list" onClick={selectCard}>
                                {cardsList.map( card => (
                                <li key={card.key} id={card.key} className="card"> 
                                    <img 
                                        className="card-img" 
                                        src={card.imageUrl} 
                                        alt={card.displayName}
                                        id={card.key} /> 
                                    <span id={card.key}> {card.displayName} </span>
                                 </li>)  )}

                            </ul>
                        ) }
                        
                    </div>
                    
                
                </div>
                {/* <input onChange={debouncedInp(fetchCards, 1000)()}/>  */}
            </div>
        </div>
    )
}


export default SelectCardModal;